import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { BREAKPOINTS } from '@core/constants';
import { MenuLateralService } from '@core/services/menu-lateral.service';
import { AnimationOptions } from 'ngx-lottie';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-menu-lateral',
  templateUrl: './menu-lateral.component.html',
  styleUrls: ['./menu-lateral.component.scss']
})
export class MenuLateralComponent implements OnInit {
  private subscription$: Subscription = new Subscription();
  @ViewChild(MatDrawer) drawer!: MatDrawer;
  options: AnimationOptions = {
    path: `/assets/animations/animation${
      Math.round(Math.random() * 100) % 5
    }.json`
  };
  mobile: boolean = false;
  constructor(
    private menuLateralService: MenuLateralService,
    private breakpointObserver: BreakpointObserver
  ) {}

  ngOnInit() {
    this.subscription$.add(
      this.breakpointObserver
        .observe([
          BREAKPOINTS.xs,
          BREAKPOINTS.sm,
          BREAKPOINTS.md,
          BREAKPOINTS.lg,
          BREAKPOINTS.xl
        ])
        .subscribe(result => {
          this.mobile =
            result.breakpoints[BREAKPOINTS.xs] ||
            result.breakpoints[BREAKPOINTS.sm] ||
            result.breakpoints[BREAKPOINTS.md];
        })
    );
  }
  ngAfterViewInit(): void {
    this.menuLateralService.drawer = this.drawer;
  }
  ngOnDestroy(): void {
    this.subscription$.unsubscribe();
  }
}
