import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, OnInit } from '@angular/core';
import { TokenPayload } from '@core/models/services/autenticar/tokenPayload';
import { MenuLateralService } from '@core/services/menu-lateral.service';
import { Subscription } from 'rxjs';
import { BREAKPOINTS } from '@core/constants';

@Component({
  selector: 'app-cabecalho',
  templateUrl: './cabecalho.component.html',
  styleUrls: ['./cabecalho.component.scss']
})
export class CabecalhoComponent implements OnInit {
  private subscription$: Subscription = new Subscription();
  menuAberto: boolean = true;
  usuario?: TokenPayload;

  mobile: boolean = false;

  constructor(
    public menuLateralService: MenuLateralService,

    private breakpointObserver: BreakpointObserver
  ) {}

  ngOnInit(): void {
    if (this.menuLateralService.drawer) {
      this.subscription$.add(
        this.menuLateralService.drawer.openedStart.subscribe(() => {
          this.menuAberto = true;
        })
      );
      this.subscription$.add(
        this.menuLateralService.drawer.closedStart.subscribe(() => {
          this.menuAberto = false;
        })
      );
    }

    this.subscription$.add(
      this.breakpointObserver
        .observe([
          BREAKPOINTS.xs,
          BREAKPOINTS.sm,
          BREAKPOINTS.md,
          BREAKPOINTS.lg,
          BREAKPOINTS.xl
        ])
        .subscribe(result => {
          this.mobile =
            result.breakpoints[BREAKPOINTS.xs] ||
            result.breakpoints[BREAKPOINTS.sm] ||
            result.breakpoints[BREAKPOINTS.md];
        })
    );
  }

  ngOnDestroy(): void {
    this.subscription$.unsubscribe();
  }

  toggleMenu() {
    this.menuAberto = !this.menuAberto;
    this.menuLateralService.drawer?.toggle();
  }
}
