export const environment = {
  production: true,
  baseURL: 'https://agendamento-macae-gateway.azurewebsites.net/api/v1/',
  rotasSemAutenticacao: [
    'https://agendamento-macae-gateway.azurewebsites.net/api/v1/Autenticacao/Autenticar'
  ],
  dominiosComAutenticacao: [
    'https://agendamento-macae-gateway.azurewebsites.net'
  ],
  agendamentoCarga: (() => {
    const token = localStorage.getItem('TOKEN');
    localStorage.removeItem('TOKEN');
    if (token) {
      return `https://agendamento.bmac.macae.globalship.com.br?token=${token}`;
    } else {
      return 'https://agendamento.bmac.macae.globalship.com.br';
    }
  })()
};
