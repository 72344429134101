<mat-drawer-container class="c-menu-lateral">
  <mat-drawer
    #drawer
    class="c-menu-lateral_drawer"
    [mode]="mobile ? 'over' : 'side'"
    [opened]="true"
    *ngIf="!mobile">
    <div class="body">
      <img
        src="/assets/img/petrobras.svg"
        class="c-menu-lateral_logo"
        alt="logo" />
      <div class="title">
        <p class="text">Agendamento de Cargas - BMAC</p>
      </div>
    </div>
  </mat-drawer>
  <mat-drawer-content class="c-menu-lateral_content">
    <ng-content></ng-content>
  </mat-drawer-content>
  <!-- <mat-drawer-content class="c-menu-lateral_content">
    <app-cabecalho></app-cabecalho>
    <ng-content></ng-content>
  </mat-drawer-content> -->
</mat-drawer-container>
