<div *ngIf="!mobile">
  <mat-toolbar class="c-cabecalho" [ngClass]="{ 'is-menu-aberto': menuAberto }">
    <button mat-icon-button (click)="toggleMenu()">
      <mat-icon>menu</mat-icon>
    </button>
  </mat-toolbar>
</div>
<div class="teste" *ngIf="mobile">
  <div class="body">
    <img
      src="/assets/img/petrobras.svg"
      class="c-menu-lateral_logo"
      alt="logo" />
    <div class="title"><p class="text">Agendamento de Cargas - BMAC</p></div>
  </div>
</div>
